import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import "./App.css";
import MainNavbar from "../components/MainNavbar";
import Streamdeck from "./Streamdeck";
import CookieConsent from "react-cookie-consent";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesPolicy from "./CookiesPolicy";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import ScrollToTop from "../utils/ScrollToTop";
import styled from "styled-components";

const AppWrapper = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
`;

function App() {
  return (
    <AppWrapper className="App">
      <ScrollToTop />
      <MainNavbar />
      <Switch>
        <Route
          path="/recochinera"
          render={() => (
            <Layout>
              <Streamdeck />
            </Layout>
          )}
        />
        <Route
          path="/cookies"
          render={() => (
            <Layout>
              <CookiesPolicy />
            </Layout>
          )}
        />
        <Route
          path="/privacy-policy"
          render={() => (
            <Layout>
              <PrivacyPolicy />
            </Layout>
          )}
        />
        <Route exact path="/" component={Home} />
      </Switch>

      <Footer />
      <CookieConsent
        enableDeclineButton
        declineButtonText="No lo veo"
        buttonText="Mejor eso que morirse"
        declineButtonStyle={{ backgroundColor: "gray" }}
        buttonWrapperClasses={"cookie-button-wrapper "}
        style={{
          fontFamily: "Oswald",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}
      >
        Usamos 🍪🍪 para que tu experiencia sea más cuqui *badam,tss* 😏
      </CookieConsent>
    </AppWrapper>
  );
}

export default App;
