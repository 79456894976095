import styled from "styled-components";
import texts from "../assets/texts.json";
import { useHistory } from "react-router-dom";
import GeneralSection from "../components/GeneralSection";

const Container = styled.div`
  width: 100%;
`;
const HeaderWrapper = styled.div`
  color: white;
  display: flex;
  flex-flow: column;
  background: url("/img/MainFullColor.jpg") no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  max-height: 700px;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  & > picture img {
    position: relative;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 100vh;
    background-image: url("/img/MainMobileFade.jpg");
    background-attachment: scroll;
  }
`;
const HeaderContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 50px;
  max-width: 80%;
  @media (max-width: 600px) {
    background-attachment: scroll;
    max-width: 95%;
  }
`;
const StyledTitle = styled.div`
  text-align: center;
  font-size: 3em;
  text-shadow: 1px 2px 10px var(--background-non-primary);
  line-height: 1;

  & > h1 {
    margin: 5px auto;
  }
  & > h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;

const YoutubeContainer = styled.div`
  align-items: center;
  width: 100%;
  & > a {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: white;
  }
  & > a img {
    width: 150px;
  }
`;
const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25px 0;
`;
const ContactItem = styled.div`
  width: 100px;
  & > a {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: white;
  }
  & > a img {
    width: 95%;
  }

  @media (max-width: 600px) {
    width: 75px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  width: 150px;
  height: 150px;
  padding: 20px;
  color: white;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  background-color: var(--secondary-action-color);
  :hover {
    background-color: var(--secondary-action-color-hover);
    cursor: pointer;
  }
`;

const CardText = styled.p`
  font-size: 25px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.two-columns {
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  width: 200px;
  & > img {
    width: 100%;
  }
`;

/*Data*/

const socialItems = [
  {
    name: "Instagram",
    link: "https://www.instagram.com/d.a.dos/",
    img: "/img/instagram.png",
    alt: "Instragram D.a.dos",
  },
  {
    name: "Twitter",
    link: "https://www.twitter.com/d_a_dos/",
    img: "/img/twitter.png",
    alt: "Twitter D.a.dos",
  },
  {
    name: "E-mail",
    link: "mailto: juegos.d.a.dos@gmail.com",
    img: "/img/at.png",
    alt: "Mándanos un e-mail",
  },
];

const TagList = ["#reseñas", "#tops", "#viajes", "#humor", "#juegosDeMesa"];

const Home = () => {
  const history = useHistory();

  return (
    <Container>
      <HeaderWrapper>
        <HeaderContent>
          <StyledTitle>
            <h1>Somos D.a.dos</h1>
            <h4>Que no dados</h4>
          </StyledTitle>
          <YoutubeContainer>
            <a href="https://www.youtube.com/channel/UCTKkYanUrTPWdD1Y6148e3w">
              <img src="/img/youtube.png" alt="D.a.dos en Youtube" />
              Nuestro Canal
            </a>
          </YoutubeContainer>
        </HeaderContent>
      </HeaderWrapper>
      <GeneralSection title="Cosicas">
        <CenteredContainer>
          <Card onClick={() => history.push("/recochinera")}>
            <img
              src="/img/recochinera.svg"
              alt="Recochineate con nuestros sonidos"
            />
            <CardText>La recochinera</CardText>
          </Card>

          <Card
            onClick={() => {
              window.location.href =
                "https://microfonados.notion.site/microfonados/Microfon-a-dos-by-D-a-dos-35a8147e86b246ec9fe2c9b2ffd5ef00";
            }}
          >
            <img
              src="/img/podcast.svg"
              alt="Recochineate con nuestros sonidos"
            />
            <CardText>El podcast</CardText>
          </Card>
        </CenteredContainer>
      </GeneralSection>
      <GeneralSection title="Nosotros">
        <LogoContainer>
          <img src="/img/logo.png" alt="D.a.dos Logo" />
        </LogoContainer>
        <div className="text-content">{texts.about}</div>{" "}
        <h4 className="highlight">{texts.contact.buyme}</h4>
        <CenteredContainer>
          <a
            href="https://www.buymeacoffee.com/somosdados"
            target="_blank"
            alt="¡Invítanos un café!"
            rel="noreferrer"
          >
            <img
              className="buyme-btn"
              src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
              alt="Buy Me A Coffee"
              style={{ height: "60px", width: "217px" }}
            />
          </a>
        </CenteredContainer>
        <div className="text-content">{texts.contact.buyme_explain}</div>
      </GeneralSection>

      <GeneralSection title="Contacto">
        <ContactContainer>
          {socialItems.map((social, index) => {
            return (
              <ContactItem key={index}>
                <a href={social.link}>
                  <img src={social.img} alt={social.alt} />
                  <span>{social.name}</span>
                </a>
              </ContactItem>
            );
          })}
        </ContactContainer>
      </GeneralSection>
    </Container>
  );
};

export default Home;
