import useSound from "use-sound";
import styled from "styled-components";

import webSounds from "../assets/sounds/websounds.mp3";
import Header from "../components/Header";
import SectionDivider from "../components/SectionDivider";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  color: white;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 330px;
  height: 330px;
`;

const StyledSoundButton = styled.input`
  -webkit-appearance: none;
  width: 100px;
  height: 100px;
  border-color: var(--primary-action-color);
  border-radius: 10px;
  white-space: normal;
  background-color: var(--primary-action-color);
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
  font-family: "Montserrat", sans-serif;
  &:hover {
    cursor: pointer;
    background-color: var(--primary-action-color-hover);
  }
`;

const sounds = [
  { id: "bazinga", label: "Zas, en toda la boca" },
  { id: "gameover", label: "Game Over" },
  { id: "lastima", label: "Qué Lástima" },
  { id: "arrepienti", label: "Arrepienti" },
  { id: "losienti", label: "Lo sienti" },
  { id: "aprendiendi", label: "Apren diendi" },
  { id: "golpe", label: `Golpe` },
  { id: "alerta", label: `Alerta` },
  { id: "loco", label: `LOCO` },
];

const Streamdeck = () => {
  const [play] = useSound(webSounds, {
    sprite: {
      bazinga: [0, 1216],
      gameover: [1841, 2702],
      lastima: [5360, 1546],
      arrepienti: [7451, 1163],
      losienti: [9461, 1267],
      aprendiendi: [11899, 1156],
      golpe: [13871, 599],
      alerta: [14925, 1366],
      loco: [16511, 1004],
    },
  });

  const handlePlay = (soundName) => {
    switch (soundName) {
      case "bazinga":
        return play({ id: "bazinga" });
      case "gameover":
        return play({ id: "gameover" });
      case "lastima":
        return play({ id: "lastima" });
      case "arrepienti":
        return play({ id: "arrepienti" });
      case "losienti":
        return play({ id: "losienti" });
      case "aprendiendi":
        return play({ id: "aprendiendi" });
      case "golpe":
        return play({ id: "golpe" });
      case "alerta":
        return play({ id: "alerta" });
      case "loco":
        return play({ id: "loco" });
      default:
        return null;
    }
  };

  const imageURL = "/img/StreamdeckHeader.jpg";
  const title = "La Recochinera";
  return (
    <Container>
      <Header imageURL={imageURL} title={title} />
      <SectionDivider title="¡Dale caña!" />
      <ButtonWrapper>
        {sounds.map((sound, index) => (
          <StyledSoundButton
            key={index}
            type="button"
            id={sound.id}
            onClick={(evt) => handlePlay(evt.currentTarget.id)}
            value={sound.label}
          />
        ))}
      </ButtonWrapper>
    </Container>
  );
};

export default Streamdeck;
