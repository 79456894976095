import Header from "../components/Header";
import GeneralSection from "../components/GeneralSection";
import styled from "styled-components";

const StyledListItem = styled.p`
  ::before {
    content: "🍪 ";
  }
`;

const imgURL = "/img/CookiesPolicyHeader.jpg";
const title = "Política de Cookies";

const CookiesPolicy = () => {
  return (
    <div>
      <Header imageURL={imgURL} title={title} />
      <GeneralSection title="¿De qué va esto?">
        <p>
          A continuación vamos a informarte sobre qué son y cómo usamos las
          "cookies" en nuestro sitio. Acorde a lo lo dispuesto en el artículo
          22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de
          la Información y de Comercio Electrónico.
        </p>
      </GeneralSection>
      <GeneralSection title="¿Qué es una cookie?">
        <p>
          Una cookie es un fichero que se descarga en tu ordenador al acceder a
          determinadas páginas web.
        </p>
        <p>
          Las cookies permiten a una página web, entre otras cosas, almacenar y
          recuperar información sobre el uso que haces de la web. No sólo
          guardando información sobre la actividad que realizas con fines
          estadísticos, sino también ayudando a que determinados servicios
          funcionen de manera cómoda para ti. Por ejemplo: reconocerte cuando
          vuelves a entrar, o guardar los artículos que ya habías metido en tu
          carrito entre sesiones.
        </p>
        <p>
          La información estadística que se recoge a menudo ayuda a mejorar la
          usabilidad de los sitios web así como medir la acogida que tienen las
          funcionalidades.
        </p>
      </GeneralSection>
      <GeneralSection title="¿Qué cookies usamos en esta web?">
        <StyledListItem>
          <strong>Cookies de análisis:</strong> estas cookies nos ayudan a
          recabar información sobre la cantidad de usuarios que usan nuestra web
          y las cosas que ofrecemos (como "La Recochinera"). Usamos esta
          información para ver la acogida de lo que mostramos y también analizar
          si la experiencia de usuario es la adecuada y en qué puntos podemos
          mejorarla.
          <p>
            Habrás escuchado más de una vez el uso de Google Analytics. De estas
            cookies es de donde sale la información. Para que te quedes
            tranquil@ la información estadística es anónima.
          </p>
        </StyledListItem>
        <StyledListItem>
          <strong>Cookies técnicas:</strong> en ocasiones utilizamos cookies que
          te permiten acceder a determinados servicios. Por ejemplo, una
          autenticación con Google, o Twitter para hacer una encuesta o acceder
          a un contenido exlusivo con un correo.
          <p>
            Son cookies que necesitamos para que el sitio funcione correctamente
            y puedas acceder a las funcionalidades que ofrecemos.
          </p>
        </StyledListItem>
      </GeneralSection>
      <GeneralSection title="Desactivar las cookies">
        Puedes permitir, bloquear y eliminar las cookies que compartes tanto con
        esta web como con cualquier otra a través de la configuración de
        privacidad de tu navegador. Siempre puedes acceder en "Modo Incógnito"
        para evitar que se comparta ninguna cookie. Pero debes tener en cuenta
        que algunas web no podrán funcionar correctamente si bloqueas la
        compartición de cookies técnicas.
        <p>
          <small>
            La imagen de la cabecera pertenece a{" "}
            <a href="https://unsplash.com/@olianayda?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Olia Nayda
            </a>{" "}
            y puedes encontrarla en{" "}
            <a href="https://unsplash.com/s/photos/cookies?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </small>
        </p>
      </GeneralSection>
    </div>
  );
};

export default CookiesPolicy;
