import Header from "../components/Header";
import GeneralSection from "../components/GeneralSection";

const imgURL = "/img/PrivacyPolicyHeader.jpg";
const title = "Política de Privacidad";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header imageURL={imgURL} title={title} />
      <GeneralSection title="¿De qué va esto?">
        <p>
          En esta página te vamos a contar qué datos de usuario recogemos y para
          qué los usamos en cumplimiento con la RGPD.
        </p>
      </GeneralSection>
      <GeneralSection title="¿Qué datos recogemos?">
        <p>
          En la web utilizamos cookies que nos ayudan a recoger datos
          estadísticos a través de herramientas de estadística. Estos datos son
          anónimos y no alojamos información personal en relación a ellos. Lo
          hacemos a través de Google Analytics. En algunas ocasiones,
          almacenamos datos como tu e-mail, tu nombre o información adicional
          que nos proporciones para que puedas participar en determinadas
          actividades que hacemos como concursos, encuestas, o similar.
        </p>
      </GeneralSection>
      <GeneralSection title="Responsable del tratamiento">
        <p>
          Los datos que recabemos directamente cuando nos los proporciones serán
          tratados de manera confidencial y los almacenaremos en nuestra base de
          datos alojada en los Servidores de Google (Firebase o Google Drive)
        </p>
        <p>
          La responsable del tratamiento de estos es Nadine Thêry y puedes
          contactar con nosotros en{" "}
          <a href="juegos.d.a.dos@gmail.com">juegos.d.a.dos@gmail.com</a> para
          solicitar cualquier acción de modificación o eliminación de estos
          datos
        </p>
      </GeneralSection>
      <GeneralSection title="Finalidad">
        <p>
          Cuando compartas datos privados con nosotros te indicaremos para qué
          son. Pero en general, serán en relación a actividades que organicemos
          y en las que quieras participar como concursos, encuestras o
          similares, para poder comunicarnos contigo y anunciar ganadores o
          compartir el trabajo que hayas realizado para la actividad si es el
          caso.
        </p>
      </GeneralSection>
      <GeneralSection title="Conservación">
        <p>
          Conservaremos los datos durante el tiempo necesario para cumplir con
          la finalidad para la que los hemos recabado y para lo que se derive
          posteriormente de ella.
        </p>
      </GeneralSection>
      <GeneralSection title="Comunicación de datos">
        <p>
          Los datos no se comunicarán a terceros, y en los casos de estadística
          nunca se comparten datos personales reconocibles. Esto es, que si
          recogemos cookies sobre tu navegación, nunca compartiremos con Google
          Analytics tu nombre o ninguno de los datos personales que nos hayas
          comunicado. Esto forma parte, además, de los acuerdos adoptados con
          Google Analytics. Por tanto, los datos que nos compartas, serán
          tratatos de manera confidencial. Con la única salvaguarda de que
          debamos darlos como resultado de una obligación legal (un
          requerimiento judicial o similar).
        </p>
      </GeneralSection>
      <GeneralSection title="Derechos de los interesados">
        <p>
          Por supuesto, tienes derecho a obtener la confirmación sobre el
          tratamiento que hacemos de tus datos. Así como a acceder, rectificar,
          pedir que borremos u oponerte al tratamiento de los datos contactando
          con nosotros en el correo más arriba mencionado.
        </p>

        <small>
          La imagen de la cabecera pertenece a{" "}
          <a href="https://unsplash.com/@markusspiske?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Markus Spiske
          </a>{" "}
          y puedes encontrarla en{" "}
          <a href="https://unsplash.com/s/photos/private?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </small>
      </GeneralSection>
      <p></p>
    </div>
  );
};

export default PrivacyPolicy;
