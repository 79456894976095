import SectionDivider from "./SectionDivider";
import styled from "styled-components";

const StyledContainer = styled.div``;
const StyledContent = styled.div`
  padding: 20px 200px;
  color: white;
  line-height: 2;

  @media (max-width: 600px) {
    padding: 20px 20px;
    font-size: 16px;
  }
  @media (min-width: 601px) and (max-width: 768px) {
    padding: 20px 80px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    padding: 20px 100px;
  }
`;

const GeneralSection = (props) => {
  return (
    <StyledContainer>
      <SectionDivider title={props.title} />
      <StyledContent>{props.children}</StyledContent>
    </StyledContainer>
  );
};

export default GeneralSection;
