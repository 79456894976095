import styled from "styled-components";

const HeaderWrapper = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  max-height: 300px;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 600px) {
    height: 100%;
  }
`;
const HeaderContent = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 80%;
  @media (max-width: 600px) {
    background-attachment: scroll;
    max-width: 95%;
  }
`;
const StyledTitle = styled.div`
  text-align: center;
  font-size: 3em;
  text-shadow: 1px 2px 10px var(--background-non-primary);
  line-height: 1;

  & > h1 {
    margin: 5px auto;
  }
  & > h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const Header = ({ imageURL, title = "", subtitle = "" }) => {
  return (
    <HeaderWrapper>
      <StyledImage src={imageURL} />
      <HeaderContent>
        {title && (
          <StyledTitle>
            <h1>{title}</h1>
          </StyledTitle>
        )}
        {subtitle.length > 0 && (
          <StyledTitle>
            <h4>{title}</h4>
          </StyledTitle>
        )}
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default Header;
