import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  :hover {
    cursor: pointer;
  }

  & > img {
    height: 100%;
    padding: 5px;
  }
  & > span {
    font-family: "Oswald", sans-serif;
    color: white;
    font-size: 20px;
  }
`;

const LinksContainer = styled.div``;

const MainNavbar = () => {
  const history = useHistory();
  return (
    <Container>
      <LogoContainer onClick={() => history.push("/")}>
        <img src="/img/logoNavbar.png" alt="D.a.dos Logo" />
        <span>D.a.dos</span>
      </LogoContainer>

      <LinksContainer>
        <div>
          <a
            href="https://www.buymeacoffee.com/somosdados"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
              alt="Buy Me A Coffee"
              style={{ height: "75%", width: "125px", margin: "5px" }}
            />
          </a>
        </div>
      </LinksContainer>
    </Container>
  );
};

export default MainNavbar;
