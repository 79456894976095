import styled from "styled-components";

const StyledContainer = styled.div`
  text-align: center;
  padding-top: 10px;
  width: 100%;
  background-color: var(--primary-action-color);
  color: white;
`;

const StyledTitle = styled.h1`
  margin: 0;
`;
const SectionDivider = ({ title }) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
    </StyledContainer>
  );
};

export default SectionDivider;
