import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledFooter = styled.footer`
  height: 50px;
  width: 100%;

  display: flex;
  justify-content: space-around;
  background-color: var(--cards-background);
  color: white;
  flex-wrap: wrap;
  align-items: center;
  > a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <StyledFooter>
      <p>Copyright D.a.dos {currentYear} </p>
      <Link to="/cookies">Política de Cookies</Link>
      <Link to="/privacy-policy">Política de Privacidad</Link>
    </StyledFooter>
  );
};

export default Footer;
