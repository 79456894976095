import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 50px 0 0 0;
`;

const Layout = (props) => {
  return <StyledContainer>{props.children}</StyledContainer>;
};

export default Layout;
